import classNames from 'classnames';
import { Icon } from '@/components/atoms/Icon';

import styles from './index.module.scss';

interface RepairDetailProps {
  readonly className?: string;
  readonly serviceName: string;
}

export function RepairDetail({ className, serviceName }: RepairDetailProps): JSX.Element {
  return (
    <div className={classNames(RepairDetail.displayName, className, styles.HeaderSection, styles.component)}>
      <div className={styles.serviceIconCircle}>
        <Icon className={styles.serviceIcon} legacy={false} width={12} height={12} id="general/check" />
      </div>
      <p className={styles.serviceTitle}>{serviceName}</p>
    </div>
  );
}

RepairDetail.displayName = 'RepairDetail';
