import type { HTMLAttributes } from 'react';
import React from 'react';
import classNames from 'classnames';
import { ReviewProvider } from '$constants/review-providers';
import { Icon, type IconProps } from '@/components/atoms/Icon';

const roundToPointFive = (num: number) => (Math.round((num * 10) / 5) * 5) / 10;

export interface StarRatingProps extends HTMLAttributes<HTMLDivElement> {
  readonly provider: ReviewProvider;

  /**
   * @min 1
   * @max 5
   * @step 0.5
   */
  readonly stars: number;
  readonly starWidth?: number;
  readonly starColor?: string;
}

const getRatingProvider = (provider: ReviewProvider) => {
  if (Object.values(ReviewProvider).includes(provider)) return provider;
  console.warn(`StarRating component received invalid provider(${provider})`);
  return ReviewProvider.TRUSTPILOT;
};

const getStarsNumber = (stars: number) => {
  if (stars < 1 || stars > 5) {
    return 5;
  }
  return stars;
};

const getStarsData = (starsNo: number): [number, boolean, number] => {
  const rating = roundToPointFive(starsNo);
  const fullStarsNo = parseInt(rating.toString(), 10);
  const hasHalfStar = rating > fullStarsNo;
  const emptyStarsNo = 5 - (fullStarsNo + +hasHalfStar);
  return [fullStarsNo, hasHalfStar, emptyStarsNo];
};

export function StarRating({
  provider,
  stars,
  starWidth = 20,
  starColor = '#219653',
  className,
  ...props
}: StarRatingProps): JSX.Element {
  const ratingProvider = getRatingProvider(provider);
  const starsNo = getStarsNumber(stars);

  const [fullStars, halfStar, emptyStars] = getStarsData(starsNo);

  const starProps = { width: starWidth, color: starColor };

  const fullStarsProps: IconProps[] = Array<IconProps>(fullStars)
    .fill(starProps as IconProps)
    .map((iconProps, i) => ({
      ...iconProps,
      key: `star-full-${ratingProvider}-${i}`,
      id: `star-full-${ratingProvider}`,
    }));

  const emptyStarsProps: IconProps[] = Array(emptyStars)
    .fill(starProps)
    .map((iconProps, i) => ({
      ...iconProps,
      key: `star-empty-${ratingProvider}-${i}`,
      id: `star-empty-${ratingProvider}`,
    }));

  return (
    <div className={classNames(StarRating.displayName, className)} {...props}>
      {fullStarsProps.map((iconProps, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Icon {...iconProps} key={i} />
      ))}
      {halfStar && <Icon id={`star-half-${ratingProvider}`} width={starWidth} color={starColor} />}
      {emptyStarsProps.map((iconProps, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Icon {...iconProps} key={i} />
      ))}
    </div>
  );
}

StarRating.displayName = 'StarRating';
