'use client';

import type { ComponentPropsWithoutRef } from 'react';
import React, { useState } from 'react';
import classNames from 'classnames';
import { z } from 'zod';
import { Card } from '$src-components/atoms/Card';

import { CmsShortTextSchema } from '$cms/types';
import { HeadingLevels } from '@/components/atoms/HeadingLevels';
import { ShowAllToggle } from '@/components/atoms/ShowAllToggle';
import { Img } from '@/components/atoms/Img';
import type { CmsServerComponent } from '@/types/components';
import styles from './index.module.scss';

const GarageAboutUsSchema = z.object({
  title: CmsShortTextSchema,
  showMoreText: CmsShortTextSchema,
  showLessText: CmsShortTextSchema,
  garageImageAltText: CmsShortTextSchema,
});

const getComponentTranslations = (data: unknown) => {
  const { title, showMoreText, showLessText, garageImageAltText } = GarageAboutUsSchema.parse(data);
  return {
    title: title.value,
    showMoreText: showMoreText.value,
    showLessText: showLessText.value,
    garageImageAltText: garageImageAltText.value,
  };
};

export interface GarageAboutUsCardProps extends ComponentPropsWithoutRef<'div'> {
  readonly className?: string;
  readonly garageId?: string;
  readonly description: string;
}
export const GarageAboutUsCard: CmsServerComponent<GarageAboutUsCardProps> = function GarageAboutUsCard({
  className,
  description,
  garageId,
  id,
  data,
}): JSX.Element {
  const { title, showMoreText, showLessText } = getComponentTranslations(data);

  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <Card className={classNames(styles.component, className)} elevation={2} cornerRadius={2} id={id}>
      <div className={styles.TextWrapper}>
        <HeadingLevels className={styles.Title} semanticLevel={3} styleLevel={3}>
          {title}
        </HeadingLevels>
        <div
          className={classNames(
            styles.descriptionContainer,
            showAll ? styles.descriptionVisible : styles.descriptionHidden
          )}
        >
          <p>{description}</p>
        </div>
        <ShowAllToggle
          className={styles.showAllToggle}
          toggleShowAll={toggleShowAll}
          showAll={showAll}
          showMoreText={showMoreText}
          showLessText={showLessText}
        />
      </div>
      {garageId && (
        <div className={styles.ImageWrapper}>
          <Img
            className={styles.Image}
            priority
            fill
            loading="eager"
            quality={75}
            sizes="(max-width: 904px) 50vw, 33vw"
            src={`https://res.cloudinary.com/fxtr/image/upload/garages/garage-images/${garageId}/garageImage2.jpg`}
            alt="Garage image"
            provider="cloudinary"
          />
        </div>
      )}
    </Card>
  );
};
GarageAboutUsCard.displayName = 'GarageAboutUsCard';
GarageAboutUsCard.schema = GarageAboutUsSchema;
