import type { ComponentPropsWithoutRef } from 'react';
import React from 'react';
import classNames from 'classnames';

import { StarRating } from '$quantum/atoms/StarRating';
import { ReviewProvider } from '$constants/review-providers';
import { Img } from '@/components/atoms/Img';
import styles from './index.module.scss';
import type { AppGarage } from 'pages/api/getGaragesByLatLong';

export interface GoogleReviewsSummarySectionProps extends ComponentPropsWithoutRef<'div'> {
  readonly ratingNumber?: AppGarage['ratingNumber'];
  readonly translations: {
    readonly reviewsNumberText: string;
  };
}

export function GoogleReviewsSummarySection({
  className,
  ratingNumber,
  translations: { reviewsNumberText },
}: GoogleReviewsSummarySectionProps): JSX.Element {
  return (
    <div className={classNames(GoogleReviewsSummarySection.displayName, styles.component, className)}>
      <div className={styles.businessReviewsSummary}>
        <div className={styles.scoreWrapper}>
          <p className={styles.score}>{ratingNumber && ratingNumber.toFixed(1)}</p>
          <StarRating className={styles.stars} provider={ReviewProvider.GOOGLE} stars={ratingNumber || 4} />
        </div>
        <div className={styles.reviewsAttribution}>
          <Img
            className="usp-image"
            provider="cloudinary"
            src="https://res.cloudinary.com/fxtr/image/upload/v1688549036/landing/logos/google_review_fv9hui.svg"
            alt="Google logo"
            layout="fixed"
            width={35}
            height={35}
          />
          <span className={styles.attributionText}>{reviewsNumberText}</span>
        </div>
      </div>
    </div>
  );
}
GoogleReviewsSummarySection.displayName = 'GoogleReviewsSummarySection';
