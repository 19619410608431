'use client';

/* eslint-disable array-callback-return */
import React, { createRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import { DEFAULT_LAT_LNG } from '$util/gmaps';
import { Img } from '@/components/atoms/Img';
import styles from './map.module.scss';

const CLOUDINARY_URL = 'https://res.cloudinary.com/fxtr/image/upload/v1656437008/landing';

let keepMap = null;
const keepMarkers = {};

const mapRef = createRef();

export function GoogleMap({ className, apiKey, location = DEFAULT_LAT_LNG.UK }) {
  const [showLoader, setShowLoader] = useState(true);

  const setGarageMarker = (garageData, imageUrl, markerWidth, markerHeight) => {
    if (!keepMap) return;

    const garageMarker = new window.google.maps.Marker({
      position: {
        lat: garageData.lat,
        lng: garageData.lng,
      },
      map: keepMap,
      icon: {
        url: imageUrl,
        scaledSize: new window.google.maps.Size(markerWidth, markerHeight),
      },
    });

    keepMarkers[garageData.garageId] = garageMarker;
  };

  const loadMap = () => {
    if (!process.browser) return;
    setShowLoader(false);

    if (mapRef.current instanceof Element) {
      const locationCenterLatLng = {
        lat: Number.parseFloat(location.centerLatLng.lat),
        lng: Number.parseFloat(location.centerLatLng.lng),
      };

      const map = new window.google.maps.Map(mapRef.current, {
        center: locationCenterLatLng,
        zoom: location.zoom,
        mapTypeId: 'roadmap',
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: location.zoomControl,
        zoomControlOptions: {
          position:
            location.zoomControlOptions === 'TOP_RIGHT' && window.google.maps.ControlPosition.TOP_RIGHT,
        },
      });
      map.data.setStyle(() => ({
        fillColor: '#58b0ff',
        fillOpacity: 0.4,
        strokeWeight: 1,
      }));

      keepMap = map;

      setGarageMarker(
        {
          garageId: 1,
          lat: Number.parseFloat(location.centerLatLng.lat),
          lng: Number.parseFloat(location.centerLatLng.lng),
        },
        `${CLOUDINARY_URL}/icons/neutral_garage_pin_re20s9.svg`,
        90,
        100
      );
    }
  };

  useEffect(() => {
    if (typeof window === 'undefined') return;

    if (window.google_map_script_tag) {
      // when multiple components are initialized at the same time
      const waitScriptLoad = setInterval(() => {
        if (window.google) {
          loadMap();
          clearInterval(waitScriptLoad);
        }
      }, 100);
    } else if (!window.google) {
      window.google_map_script_tag = true;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://maps.google.com/maps/api/js?key=${apiKey}`;
      script.async = true;
      const x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(script, x);
      script.addEventListener('load', () => {
        loadMap();
      });
    }
  }, []);

  return (
    <div className={classNames(styles.GoogleMap, className)}>
      {showLoader && (
        <div className={styles.Loader}>
          <Img
            src="https://res.cloudinary.com/fxtr/image/upload/v1656437008/landing/location/static-gmap_sbnbe3.png"
            fallback="/_stories/img/mechanic.jpeg"
            alt="static map"
            key="left-img"
            priority
            loading="eager"
            quality={75}
            sizes="(max-width: 904px) 220px, 220px"
            fill
            provider="cloudinary"
          />
        </div>
      )}

      <div ref={mapRef} className={classNames('GMap', styles.GMap, showLoader && styles.hidden)} />
    </div>
  );
}
