import styles from '../../index.module.scss';
import type { AppGarageReview } from 'pages/api/getGaragesByLatLong';

interface ReviewQuoteProps {
  readonly text: AppGarageReview['text'];
  readonly authorName: AppGarageReview['authorName'];
  readonly relativeTime: AppGarageReview['relativeTime'];
}

export function ReviewQuote({ text, authorName, relativeTime }: ReviewQuoteProps): JSX.Element | null {
  return (
    <>
      <q className={styles.reviewText}>{text}</q>
      <div className={styles.reviewAuthor}>
        <span className={styles.reviewAuthorName}>{authorName}</span>{' '}
        <span className={styles.reviewRelativeTime}> {relativeTime}</span>
      </div>
    </>
  );
}
