import ReviewStars from '$components/basic/ReviewStars';

import styles from './index.module.scss';
import { ReviewQuote } from './components/ReviewQuote';
import type { AppGarageReview } from 'pages/api/getGaragesByLatLong';

interface GoogleReviewProps extends AppGarageReview {}

export function GoogleReview({
  rating = 4,
  text,
  authorName,
  relativeTime,
}: GoogleReviewProps): JSX.Element | null {
  if (!text) return null;

  return (
    <div className={styles.reviewCard}>
      <ReviewStars className={styles.reviewStars} stars={rating} starHeight={16} starWidth={16} />
      <ReviewQuote text={text} authorName={authorName} relativeTime={relativeTime} />
    </div>
  );
}

GoogleReview.displayName = 'GoogleReview';
